import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Container from 'components/ui/Container';
import Hero from 'components/Hero';
import ContactInfo from 'components/ContactInfo';

const NotFoundPage: React.FC = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container section>
    <Hero title="404" subtitle="congrats. you broke it."/>
    <ContactInfo />
    </Container>
  </Layout>
);

export default NotFoundPage;
